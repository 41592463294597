import React, { useContext } from 'react';

import cn from 'classnames';

import IntlContext from '@context/IntlContext';
import { useTranslate } from '@hooks';

import { bottomLinks } from './constants';
import styles from './Footer.module.css';
import { bottomMessages } from './messages';

function FooterBottomLink({ name }: {
    name: keyof typeof bottomLinks,
}) {
    const { locale } = useContext(IntlContext);
    const link = bottomLinks[name];
    const linkTitle = useTranslate(bottomMessages[name]);

    return (
        <a
            className={cn(styles.baseLink, styles.link)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={(typeof link === 'string') ? link : link[locale]}
            key={linkTitle}
        >
            {linkTitle}
        </a>
    );
}

function FooterBottom() {
    const { locale } = useContext(IntlContext);
    // eslint-disable-next-line max-len
    const vkPlayCopyright = locale === 'ru_RU' ? 'ООО «ВК». Все права защищены. Все товарные знаки являются собственностью их владельцев.' : 'VK LLC. All rights reserved. All trademarks are the property of their respective owners.';
    const vkPlayCopyrightFull = `© ${new Date().getFullYear()} ${vkPlayCopyright}`;

    return (
        <div className={styles.bottom}>
            <div className={styles.separator} />

            <div className={styles.bottomContent}>
                <div className={styles.copyrightWrapper}>
                    <p className={styles.age}>
                        18+
                    </p>

                    <p className={cn(styles.baseText, styles.copyright)}>
                        {vkPlayCopyrightFull}
                    </p>
                </div>

                <div className={styles.links} data-id="footer__bottom-links">
                    {(Object.keys(bottomLinks) as Array<keyof typeof bottomLinks>).map((key) => (
                        <FooterBottomLink
                            key={key}
                            name={key}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;
