import React, { useContext, useRef, useEffect } from 'react';

import { Button, Icon } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import SearchEmpty from '@components/Search/SearchEmpty';
import AppContext from '@context/AppContext';
import { useOnClickOutside } from '@hooks';

import { SearchProvider } from './context/SearchContext';
import useDropdownController from './hooks/useDropdownController';
import useSearch from './hooks/useSearch';
import messages from './messages';
import styles from './Search.module.css';
import SearchDropdown from './SearchDropdown';

import type { ItemClickEvent, SearchBlock, SearchProps } from './types';
import type { FC, ChangeEvent, FocusEvent } from 'react';

const Search: FC<SearchProps> = ({
    className,
    onClose,
    onToggle,
    onEvent = () => undefined,
    size = 'default',
}) => {
    const { formatMessage } = useIntl();

    const { includeSearch } = useContext(AppContext);
    const labelRef = useRef<HTMLDivElement>(null);
    const {
        isOpened, isContainerFocused, handleSetShouldBeOpened, handleSetContainerFocused,
    } = useDropdownController();

    const {
        searchQuery,
        data,
        isLoading,

        handleSetSearchQuery,
        loadMore,
    } = useSearch({ includeSearch, onShouldShowResult: handleSetShouldBeOpened });

    useEffect(() => {
        if (isLoading || !(searchQuery?.length > 2)) {
            return;
        }

        const foundItems = Object.values(data).reduce((acc, item) => acc + (item.searchData?.total || 0), 0);

        onEvent({
            type: 'search',
            params: {
                query: searchQuery,
                isFound: !!foundItems,
            },
        });
    }, [isLoading]);

    const handleClick = () => {
        onEvent({ type: 'search-click', params: undefined });
    };

    const handleLoadMore = (type: SearchBlock) => {
        loadMore(type);

        onEvent({
            type: 'more-click',
            params: {
                query: searchQuery,
                section: type,
            },
        });
    };

    const handleItemClick: ItemClickEvent = (url, index, section) => {
        onEvent({
            type: 'item-click',
            params: {
                query: searchQuery,
                url,
                index,
                section,
            },
        });
    };

    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        handleSetSearchQuery(event.target.value);
    };

    const handleFocusLabel = () => {
        if (isContainerFocused) {
            return;
        }

        handleSetContainerFocused(true);
    };

    useOnClickOutside(labelRef, () => {
        if (size === 'fullscreen') {
            return;
        }

        handleSetContainerFocused(false);
    });

    const handleClickClear = () => {
        handleSetSearchQuery('');
    };

    const handleFocusClear = (event: FocusEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (onToggle) {
            onToggle(isContainerFocused);
        }
    }, [isContainerFocused]);

    return (
        <SearchProvider size={size}>
            <div className={cn(styles.root, {
                [styles.fullscreen]: size === 'fullscreen',
                [styles.focused]: isContainerFocused,
            }, className)}
            >
                <div
                    className={styles.labelWrap}
                    ref={labelRef}
                >
                    <label
                        onFocus={handleFocusLabel}
                        htmlFor="eva-search-input"
                        className={styles.label}
                    >
                        <span className={styles.icon}>
                            <Icon name="search" />
                        </span>
                        <input
                            id="eva-search-input"
                            type="search"
                            autoComplete="off"
                            className={styles.input}
                            onClick={handleClick}
                            onChange={handleChangeValue}
                            value={searchQuery}
                            placeholder={formatMessage(messages.placeholderInput)}
                        />
                        {searchQuery.length > 0 && (
                            <button
                                className={styles.clearButton}
                                onClick={handleClickClear}
                                onFocus={handleFocusClear}
                            >
                                <Icon name="times" />
                            </button>
                        )}
                    </label>
                    {isOpened && (
                        <SearchDropdown
                            onLoadMore={handleLoadMore}
                            onItemClick={handleItemClick}
                            data={data}
                            isLoading={isLoading}
                        />
                    )}
                </div>
                {onClose && (
                    <Button
                        size="md"
                        appearance="overlay"
                        mode="link"
                        onClick={onClose}
                    >
                        {formatMessage(messages.controlCancel)}
                    </Button>
                )}
            </div>
            {searchQuery.length === 0 && size === 'fullscreen' && (
                <SearchEmpty />
            )}
        </SearchProvider>
    );
};

export default Search;
