import React, {
    createContext, useEffect, useRef, useState,
} from 'react';

import { Backend, inGamecenter } from '@vkplay/shared';

import { Urls } from '@services/hosts';

import type { Game, WishlistProviderProps, WishlistContextState } from './types';

const WishlistContext = createContext<WishlistContextState>({
    data: [],
    loading: true,
    count: 0,
});

const wishlistStorage = 'needWishlistUpdate';

export const WishlistContextProvider = ({
    children,
    open,
}: WishlistProviderProps) => {
    const [data, setData] = useState<Array<Game>>([]);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const firstLoad = useRef<boolean>(true);

    const getData = async () => {
        setLoading(true);

        try {
            firstLoad.current = false;
            const { results, count: currentCount } = await Backend.get(Urls.wishlist, {
                limit: 24,
            });

            if (Array.isArray(results)) {
                setData(results);
                setCount(currentCount);
                localStorage.setItem('needWishlistUpdate', '0');
            }
        } catch (e) {
            console.error(e);
            localStorage.setItem('needWishlistUpdate', '1');
        }

        setLoading(false);
    };

    useEffect(() => {
        if (inGamecenter) {
            if (firstLoad.current) {
                getData();
            }

            window.gc_window_state = (state: number) => {
                if (state === 3 && !firstLoad.current && localStorage.getItem(wishlistStorage) === '1') {
                    getData();
                }
            };
        }
    }, []);

    useEffect(() => {
        if (inGamecenter) return;

        if (open && (firstLoad.current || localStorage.getItem(wishlistStorage) === '1')) {
            getData();
        }
    }, [open]);

    return (
        <WishlistContext.Provider
            value={{
                data,
                loading,
                count,
            }}
        >
            {children}
        </WishlistContext.Provider>
    );
};

export default WishlistContext;
