import React from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter } from '@vkplay/shared';
import { Button, Icon } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { GAMES_HOST } from '@services/hosts';

import messages from './messages';
import styles from './Wishlist.module.css';

function EmptyWishlist() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const handleRedirect = () => {
        pushReachGoal({
            params: {
                category: 'library',
                action: 'goto_catalog_click',
            },
        });

        if (inGamecenter && window.gc_open_url_mainwnd) {
            window.gc_open_url_mainwnd(`https://${GAMES_HOST}/play/`);

            if (window.gc_close_window) {
                window.gc_close_window();
            }
        } else {
            window.open(`https://${GAMES_HOST}/play/`, '_self');
        }
    };

    return (
        <div
            className={cn(styles.empty, {
                [styles.emptyGC]: inGamecenter,
            })}
        >
            <span className={styles.header}>
                {formatMessage(messages.empty)}
            </span>

            <span className={styles.sub}>
                {formatMessage(messages.emptySub)}
            </span>

            <Button
                size="lg"
                appearance="neutral"
                onClick={handleRedirect}
                rightIcon={<Icon name="chevron-right-outline-24" />}
            >
                {formatMessage(messages.toCatalog)}
            </Button>
        </div>
    );
}

export default EmptyWishlist;
