import { paymentSteamUrl } from '@components/SteamRefill/helpers';
import {
    GAMES_HOST, MARKET_HOST, CLOUD_HOST, PVP_HOST,
} from '@services/hosts';

import messages from './messages';

import type { GetOverlaySchemaOptions, NavigationItemMobOverlay } from './types';

const getOverlaySchema = ({ onSteamRefillClick }: GetOverlaySchemaOptions): NavigationItemMobOverlay[] => [
    {
        id: 'live',
        label: messages.live,
        href: `https://${GAMES_HOST}/live/`,
        icon: 'live-outline',
        analyticsLabel: 'ph-menu-streams',
    },
    {
        id: 'market',
        label: messages.market,
        href: `https://${MARKET_HOST}`,
        icon: 'market-outline',
        analyticsLabel: 'ph-menu-market',
    },
    {
        id: 'cloud',
        label: messages.cloud,
        href: `https://${CLOUD_HOST}`,
        icon: 'cloud-outline',
        analyticsLabel: 'ph-menu-cloud',
    },
    {
        id: 'pvp',
        label: messages.pvp,
        href: `https://${PVP_HOST}`,
        icon: 'tournament-outline',
        analyticsLabel: 'ph-menu-pvp',
    },
    {
        id: 'steam_refill',
        label: messages.steamRefill,
        href: paymentSteamUrl,
        icon: 'wallet-outline',
        analyticsLabel: 'ph-menu-wallet',
        onClick: onSteamRefillClick,
    },
];

export default getOverlaySchema;
