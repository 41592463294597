import React from 'react';

import { useBreakpoints } from '@vkplay/ui';

import styles from './Notification.module.css';

import type { NotificationProps } from '../types';

function NotificationPicture({
    notification: {
        is_read,
        data: {
            project_name,
            image_url,
        },
    },
}: NotificationProps) {
    const { tabletMdMax: isMobile } = useBreakpoints(['tabletMdMax']);

    return (
        <div className={styles.pictureWrapper}>
            <img
                alt={project_name}
                src={image_url}
                className={styles.picture}
            />

            {(!is_read && isMobile) && (
                <div className={styles.newLabel} />
            )}
        </div>
    );
}

export default NotificationPicture;
