import React, { useContext } from 'react';

import { Loader } from '@vkplay/ui';
import cn from 'classnames';

import WishlistOverlayHeader from '@components/Wishlist/Overlay/WishlistOverlayHeader';
import WishlistContext from '@components/Wishlist/WishlistContext';
import WishlistGame from '@components/Wishlist/WishlistGame';

import EmptyWishlist from '../EmptyWishlist';
import styles from '../Wishlist.module.css';

import type { WishlistOverlayProps } from '../types';

function WishlistOverlayContent({
    onClose,
}: WishlistOverlayProps) {
    const {
        loading, data, count,
    } = useContext(WishlistContext);

    return (
        <>
            <WishlistOverlayHeader
                onClose={onClose}
                count={count}
            />

            <div
                className={cn(styles.mobileContainer, {
                    [styles.loading]: loading || data.length === 0,
                })}
            >
                {!loading && data.length === 0 && (
                    <EmptyWishlist />
                )}

                {loading && (
                    <Loader size="48" />
                )}

                {(data.length > 0 && !loading) && (
                    data.map((game) => (
                        <WishlistGame
                            game={game}
                            key={game.id}
                            isFullscreen
                        />
                    ))
                )}
            </div>
        </>
    );
}

export default WishlistOverlayContent;
