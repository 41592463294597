import type { SearchDataByType } from '@components/Search/types';

const checkHasSearchData = (data: SearchDataByType) => {
    const {
        playersSearch, teamsSearch, gamesSearch, servicesSearch, mediaSearch,
    } = data || {};

    return (playersSearch.searchData && playersSearch.searchData.items.length > 0)
        || (teamsSearch.searchData && teamsSearch.searchData.items.length > 0)
        || (gamesSearch.searchData && gamesSearch.searchData.items.length > 0)
        || (servicesSearch.searchData && servicesSearch.searchData.items.length > 0)
        || (mediaSearch.searchData && mediaSearch.searchData.items.length > 0);
};

export default checkHasSearchData;
