import React, { useContext, useEffect, useState } from 'react';

import { inGamecenter } from '@vkplay/shared';
import { Button, Icon } from '@vkplay/ui';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import AppContext from '@context/AppContext';

import messages from './messages';
import styles from './ScrollTopButton.module.css';

let lastScrollTop = 0;
let lowestScrollPosition = 0;

const ScrollTopButton = ({
    topOffset = 24,
}) => {
    const intl = useIntl();
    const { simplified } = useContext(AppContext);
    const scroller: HTMLElement | Window = document.getElementById('custom-scroller') || window;
    const isGC = inGamecenter;

    const [isVisible, setVisible] = useState(false);

    function setLastScrollTop(scrollY: number) {
        lastScrollTop = scrollY <= 0 ? 0 : scrollY;
    }

    function setLowestScrollPosition(scrollY: number) {
        lowestScrollPosition = scrollY;
    }

    function handleScroll() {
        let scrollY;
        let innerHeight;

        if (isGC && scroller instanceof HTMLElement) {
            scrollY = scroller.scrollTop;
            innerHeight = scroller.offsetHeight;
        } else {
            scrollY = window.scrollY;
            innerHeight = window.innerHeight;
        }

        if (scrollY < innerHeight) {
            setVisible(false);
            setLastScrollTop(scrollY);

            return;
        }

        if (scrollY > lastScrollTop) { // down
            if (scrollY > lowestScrollPosition) {
                setLowestScrollPosition(scrollY);
            }

            setVisible(false);
        } else if (scrollY < lastScrollTop) { // up
            if (scrollY + 100 < lowestScrollPosition) {
                setLowestScrollPosition(scrollY);
                setVisible(true);
            }
        }

        setLastScrollTop(scrollY);
    }

    useEffect(() => {
        scroller.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            scroller.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        scroller.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <Button
            className={classNames(styles.root, {
                [styles.visible]: isVisible,
                [styles.centered]: isGC || simplified,
            })}
            style={{ top: topOffset }}
            appearance="overlay"
            onClick={scrollToTop}
            leftIcon={<Icon className={styles.icon} name="arrow-down-outline" />}
        >
            {intl.formatMessage(messages.scroll_top)}
        </Button>
    );
};

export default ScrollTopButton;
