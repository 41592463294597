export default function numberToLocalString(
    number: number,
    options?: Intl.NumberFormatOptions,
) {
    if (Number.isNaN(+number)) {
        return null;
    }

    return new Intl.NumberFormat('ru-RU', options).format(number);
}
