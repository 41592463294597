import {
    isMarket, isMedia, isMini, isStore,
} from '@services/hosts';

export const ADV_CLOSED_NAME = 'gem_tech_popup_closed';

export const showPopupDomain = (
    (isStore && !window.location.pathname.includes('about'))
    || isMini
    || isMarket
    || isMedia
);

export const getLocalStorageClosed = (): boolean => {
    try {
        const storage: string|null = window.localStorage.getItem(ADV_CLOSED_NAME);

        return storage === '1';
    } catch (error) {
        console.warn('Error getting storage item', ADV_CLOSED_NAME);

        return false;
    }
};

export const setLocalStorageClosed = (): void => {
    try {
        window.localStorage.setItem(ADV_CLOSED_NAME, '1');
    } catch (error) {
        console.warn('Error setting storage item', ADV_CLOSED_NAME);
    }
};
