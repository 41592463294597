import React from 'react';

import { COLUMNS } from './constants';
import styles from './Footer.module.css';
import FooterColumn from './FooterColumn';

function FooterGrid() {
    return (
        <div className={styles.grid}>
            {COLUMNS.map((column) => (
                <FooterColumn
                    key={column.id}
                    column={column}
                />
            ))}
        </div>
    );
}

export default FooterGrid;
