import React from 'react';

import cn from 'classnames';

import type { ThemeWrapperProps } from './types';
import type { FC } from 'react';

const ThemeWrapper: FC<ThemeWrapperProps> = ({
    children, className, id, theme = 'dark',
}) => (
    <div
        className={cn('vkp-app-shell', className)}
        data-theme={theme}
        data-id={id}
    >
        {children}
    </div>
);

export default ThemeWrapper;
