import { query_string, qsStringify } from '@vkplay/shared';

import { Urls } from '@services/hosts';

export const STEAM_REFILL_PARAM = 'show_steam_refill';
export const STATS_PARAM = 'mt_link_id';
export const STATS_PARAM_GC = 'mt_track';
export const STATS_VALUE = 'drm100';
export const STATS_VALUE_GC = 'o8zls6';

type ParamObjectType = {
    [STEAM_REFILL_PARAM]?: string;
    [STATS_PARAM]?: string;
}

const qs: ParamObjectType = query_string();

const hasGCUrlParam = window.location.search.includes(STATS_PARAM_GC) || qs[STATS_PARAM] === STATS_VALUE_GC;
// eslint-disable-next-line max-len
export const hasRefillParam: boolean = qs[STEAM_REFILL_PARAM] === '1' && hasGCUrlParam;

export const paymentSteamUrl: string = `${Urls.payment_steam}?${STATS_PARAM}=${STATS_VALUE}`;

export const paymentSteamUrlGC: string = `${Urls.payment_steam}?${STATS_PARAM}=${STATS_VALUE_GC}`;

export const updateUrlParam = (removeStats?: boolean): void => {
    if (hasRefillParam || removeStats) {
        const searchParams: ParamObjectType = { ...qs };

        delete searchParams[STEAM_REFILL_PARAM];

        if (removeStats) {
            delete searchParams[STATS_PARAM];
        }

        const newSearchParams = qsStringify(searchParams);
        const separator = newSearchParams.length > 0 ? '?' : '';
        const newUrl = `${window.location.origin}${window.location.pathname}${separator}${newSearchParams}`;

        window.history.replaceState({ page: newUrl }, '', newUrl);
    }
};
