import React, { useRef, useMemo, useContext } from 'react';

import { useIntl } from 'react-intl';

import LangDropdown from '@components/LangDropdown';
import MenuItem from '@components/MobOverlay/MenuItem';
import Overlay from '@components/Overlay';
import AppContext from '@context/AppContext';
import { useOnClickOutside } from '@hooks';
import getOverlaySchema from '@navigation/overlay.schema';

import messages from './messages';
import styles from './MobOverlay.module.css';
import ProfileBlock from './ProfileBlock';

import type { MobOverlayTypes } from '@components/MobOverlay/types';
import type { FC, MouseEvent } from 'react';

const MobOverlay: FC<MobOverlayTypes> = ({ onClose }) => {
    const ref = useRef(null);
    const { formatMessage } = useIntl();
    const { onSidenavClick } = useContext(AppContext);

    const overlaySchema = useMemo(() => getOverlaySchema({
        onSteamRefillClick: (e?: MouseEvent<HTMLAnchorElement>) => {
            if (e && onSidenavClick) {
                onSidenavClick(e, 'payment_steam');
                onClose();
            }
        },
    }), [onSidenavClick]);

    useOnClickOutside(ref, onClose);

    return (
        <Overlay className={styles.mobOverlay}>
            <div className={styles.title}>{formatMessage(messages.title)}</div>

            <div className={styles.menuList}>
                {overlaySchema.map((item) => (
                    <MenuItem
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>

            <ProfileBlock />

            <div className={styles.langSelector}>
                <LangDropdown
                    isMobile
                    transparent
                />
            </div>
        </Overlay>
    );
};

export default MobOverlay;
