// TODO: убрать, когда появится вторая константа
// eslint-disable-next-line import/prefer-default-export
export const SEARCH_PICTURE_BREAKPOINTS = {
    tabletMdMax: {
        width: 90,
        height: 48,
    },
    tabletMdMin: {
        width: 120,
        height: 64,
    },
};
