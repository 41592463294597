import React, { useContext, useEffect } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { inGamecenter } from '@vkplay/shared';
import { Icon, Loader, WrapperButton } from '@vkplay/ui';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import WishlistContext from '@components/Wishlist/WishlistContext';
import WishlistGame from '@components/Wishlist/WishlistGame';
import SessionContext from '@context/SessionContext';
import { PROFILE_HOST } from '@services/hosts';

import EmptyWishlist from './EmptyWishlist';
import messages from './messages';
import styles from './Wishlist.module.css';
import topPopupStyles from '../TopNav/TopPopup.module.css';

import type { ListProps } from './types';

function List({
    setFullsize,
}: ListProps) {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();
    const {
        loading, data, count,
    } = useContext(WishlistContext);

    const {
        session: { profile },
    } = useContext(SessionContext);

    useEffect(() => {
        if (!setFullsize) return;

        if (!loading && count > 0) {
            setFullsize(false);
        } else if (loading || count === 0) {
            setFullsize(true);
        }
    }, [count, loading]);

    const handleRedirect = () => {
        if (!profile) return;

        pushReachGoal({
            params: {
                category: 'library',
                action: 'view_all_click',
            },
        });

        const url = `https://${PROFILE_HOST}/profile/${profile.slug}/games/wishlist`;

        if (inGamecenter && window.gc_open_url_mainwnd) {
            window.gc_open_url_mainwnd(url);

            if (window.gc_close_window) {
                window.gc_close_window();
            }
        } else {
            window.open(url, '_self');
        }
    };

    const withMoreButton = count > 24;

    return (
        <>
            <div className={topPopupStyles.header}>
                {(loading || (!loading && count > 0)) && (
                    <span className={topPopupStyles.title}>
                        <FormattedMessage id="profile.favorites" defaultMessage="Избранное" />

                        {!loading && (
                            <span className={topPopupStyles.titleSecondary}>
                                {count}
                            </span>
                        )}
                    </span>
                )}
            </div>

            {loading && (
                <div className={cn(topPopupStyles.loader, { [topPopupStyles.gc]: inGamecenter })}>
                    <Loader size="48" />
                </div>
            )}

            {(data.length === 0 && !loading) && (
                <EmptyWishlist />
            )}

            {(data.length > 0 && !loading) && (
                <div className={styles.customList}>
                    {data.map((game) => (
                        <WishlistGame
                            game={game}
                            key={game.id}
                        />
                    ))}
                </div>
            )}

            {(withMoreButton && !loading) && (
                <WrapperButton
                    onClick={handleRedirect}
                    className={styles.redirectButton}
                >
                    {formatMessage(messages.seeAll)}

                    <Icon name="chevron-right" />
                </WrapperButton>
            )}
        </>
    );
}

export default List;
