import React, { useState } from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { Icon, Dialog } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import styles from '@components/TopNav/Profile/Profile.module.css';
import WrapperButton from '@kit/WrapperButton';
import { getLogoutUrl } from '@services/hosts';

import messages from './messages';

function ProfileExit() {
    const { pushReachGoal } = useAnalyticsMethods();
    const { formatMessage } = useIntl();

    const [isOpen, setIsOpen] = useState(false);

    const closeDialog = () => {
        setIsOpen(false);
    };

    const openDialog = () => {
        setIsOpen(true);
    };

    const handleSuccessExit = () => {
        pushReachGoal({
            params: {
                category: 'header',
                action: 'click',
                label: 'ph-menu-logout',
            },
        });

        window.location.href = getLogoutUrl();
    };

    return (
        <>
            <WrapperButton
                onClick={openDialog}
                className={styles.exit}
            >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <Icon name="logout-outline" />
                <span>{formatMessage(messages.logout)}</span>
            </WrapperButton>

            <Dialog
                open={isOpen}
                onClose={closeDialog}
                type="dialog"
                title={formatMessage(messages.logoutTitle)}
                modalOptions={{
                    description: formatMessage(messages.logoutDescr),
                    successText: formatMessage(messages.logoutShort),
                    noSuccessText: formatMessage(messages.cancel),
                    onSuccess: handleSuccessExit,
                    isSuccessAccent: true,
                }}
                zIndex={1000000}
            />
        </>
    );
}

export default ProfileExit;
