import type { UseSearchDataOptions } from './types';

const getSupportData: UseSearchDataOptions['getStaticData'] = (query) => {
    const SUPPORT_REGEX = /подд|поде|сап|тех|помощ|служб|sup|tech/gi;

    const hasMatch = SUPPORT_REGEX.test(query);

    if (!hasMatch) {
        return null;
    }

    return {
        total: 1,
        items: [{
            title: 'support',
            url: 'https://support.vkplay.ru/',
            icon: 'help-outline',
            tags: [],
            extra: {},
        }],
        next: null,
        prev: null,
    };
};

export default getSupportData;
