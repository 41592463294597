import React, {
    useEffect, useState, type FC,
} from 'react';

import cn from 'classnames';
import { Scrollbars, type positionValues } from 'react-custom-scrollbars-2';

import styles from './ScrollableArea.module.css';

import type { ScrollableAreaProps } from './types';

const ScrollableArea: FC<ScrollableAreaProps> = (props) => {
    const {
        disableHorizontal, onUpdate, viewClassName, isDisabled, children, ...restProps
    } = props;

    const [isScrollable, setIsScrollable] = useState(false);
    const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const onUpdateHandler = (e: positionValues): void => {
        if (typeof onUpdate === 'function') {
            onUpdate(e);
        }

        setHasHorizontalScroll(e.scrollWidth > e.clientWidth);
        setHasVerticalScroll(e.scrollHeight > e.clientHeight);
    };

    useEffect(() => {
        setIsScrollable(hasVerticalScroll || hasHorizontalScroll);
    }, [hasHorizontalScroll, hasVerticalScroll]);

    if (isDisabled) {
        return children;
    }

    return (
        <Scrollbars
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderTrackVertical={(props) => <div {...props} className={cn(styles.track, styles.vertical)} />}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderThumbVertical={(props) => <div {...props} className={cn(styles.thumb, styles.vertical)} />}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderThumbHorizontal={(props) => (
                <div
                    {...props}
                    className={cn(styles.thumb, styles.horizontal, {
                        [styles.disabled]: disableHorizontal,
                    })}
                />
            )}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderTrackHorizontal={(props) => (
                <div
                    {...props}
                    className={cn(styles.track, styles.horizontal, {
                        [styles.disabled]: disableHorizontal,
                    })}
                />
            )}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            renderView={(props) => (
                <div
                    {...props}
                    className={cn(
                        styles.view,
                        viewClassName,
                        {
                            [styles.scrollable]: isScrollable,
                            [styles.vertical]: hasVerticalScroll,
                            [styles.horizontal]: hasHorizontalScroll,
                            [styles.disabled]: disableHorizontal,
                        },
                    )}
                />
            )}
            onUpdate={onUpdateHandler}
            {...restProps}
        >
            {children}
        </Scrollbars>
    );
};

export default ScrollableArea;
