import React, {
    memo,
    useContext, useEffect, useRef, type FC, type MouseEvent,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import cn from 'classnames';

import AppContext from '@context/AppContext';
import { useTranslate } from '@hooks';
import { getSubnavSchema } from '@navigation/utils';
import noop from '@utils/noop';

import styles from './Subnav.module.css';

import type { NavigationItemBasic } from '@/navigation/types';

const PRETTY_GAP = 36; // красивое

const SubnavLink: FC<NavigationItemBasic> = ({
    id,
    isActive,
    href,
    onClick,
    label,
}) => {
    const intlLabel = useTranslate(label);

    return (
        <a
            id={`eva-subnav-${id}`}
            className={cn(styles.link, {
                [styles.current]: isActive,
            })}
            key={href}
            href={href}
            onClick={onClick}
        >
            {intlLabel}
        </a>
    );
};

const Subnav: FC = () => {
    const {
        preset,
        subnav,
        subnavSlot,
        onSubnavClick,
        subnavTitleCnt,
        currentPathname,
    } = useContext(AppContext);

    const containerRef = useRef<HTMLDivElement>(null);

    const { schema, label } = getSubnavSchema({
        preset,
        subnav,
        currentPathname,
    });

    const title = useTranslate(label);

    const { pushReachGoal } = useAnalyticsMethods();

    function initScrollRecalc() {
        const container = containerRef.current;

        if (!container || container.scrollWidth <= container.clientWidth) {
            return;
        }

        const activeItem = container.getElementsByClassName(styles.current)[0];

        if (!activeItem) {
            return;
        }

        const itemRect = activeItem.getBoundingClientRect();

        if (itemRect.left < 0) {
            container.scrollLeft = container.scrollLeft + itemRect.left - PRETTY_GAP;

            return;
        }

        if (itemRect.right > window.innerWidth) {
            container.scrollLeft = container.scrollLeft + itemRect.right - window.innerWidth + PRETTY_GAP;
        }
    }

    useEffect(() => {
        initScrollRecalc();
    }, []);

    if (!schema?.length) {
        return null;
    }

    const isSubnavVisible = !!schema.find((item) => item.isActive);
    const hasSubnavCnt = typeof subnavTitleCnt === 'number';

    function getClickHandler(item: NavigationItemBasic) {
        return (e: MouseEvent<HTMLAnchorElement>) => {
            pushReachGoal({
                params: {
                    category: 'header',
                    action: 'subnav_click',
                    label: item.analyticsLabel || `ph-subnav-${preset}-${item.id}`,
                },
            });

            if (item.onClick) {
                item.onClick(e, item.id);
                setTimeout(initScrollRecalc, 0);

                return;
            }

            if (onSubnavClick) {
                onSubnavClick(e, item.id);
                setTimeout(initScrollRecalc, 0);

                return;
            }

            return noop;
        };
    }

    return (
        <div className={cn(styles.subnav, {
            [styles.visible]: isSubnavVisible,
        })}
        >
            <div className={styles.menu} ref={containerRef}>
                {schema.map((item) => (
                    <SubnavLink
                        {...item}
                        key={item.href}
                        onClick={getClickHandler(item)}
                    />
                ))}
            </div>
            <div className={styles.container}>
                <span className={styles.title}>
                    {title}
                    {hasSubnavCnt && (
                        <span className={styles.cnt}>
                            {subnavTitleCnt}
                        </span>
                    )}
                </span>
                <div className={styles.slot}>
                    {subnavSlot}
                </div>
            </div>
        </div>
    );
};

export default memo(Subnav);
