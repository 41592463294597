import { useCallback, useState } from 'react';

import { Backend } from '@vkplay/shared';

import type { UseSearchDataOptions, SearchResponse } from '@components/Search/types';

const useSearchData = <D extends SearchResponse = SearchResponse>({
    url,
    defaultQuerySet = {},
    getStaticData,
}: UseSearchDataOptions) => {
    const [searchData, setSearchData] = useState<D | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);

    const handleSearch = useCallback(async (value: string) => {
        setIsLoading(true);
        setIsError(false);

        try {
            let response;

            if (getStaticData) {
                response = getStaticData(value);
            } else {
                response = await Backend.get(url, {
                    ...defaultQuerySet,
                    query: value,
                });
            }

            setSearchData(response);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const loadMore = useCallback(async () => {
        if (!searchData?.next) {
            return;
        }

        // Fix for PVP endpoints, which returns http protocol in the next and prev fields
        const fixedUrl = searchData.next.startsWith('http://')
            ? searchData.next.replace('http://', 'https://')
            : searchData.next;

        setIsLoading(true);
        setIsError(false);

        try {
            const response = await Backend.get(fixedUrl);

            setSearchData((prev) => ({
                ...response,
                items: [
                    ...(prev?.items || []),
                    ...(response.items || []),
                ],
            }));
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [searchData?.next]);

    return {
        searchData,
        handleSearch,
        loadMore,
        hasNextPage: Boolean(searchData?.next),
        isLoading,
        isError,
    };
};

export default useSearchData;
