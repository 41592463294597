import { CLOUD_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${CLOUD_HOST}`;

const cloud = {
    id: 'cloud',
    label: messages.cloud,
    href: `${ORIGIN}/`,
    icon: 'cloud-outline',
    analyticsLabel: 'ph-menu-catalog-cloud',
    subnav: [
        {
            label: messages.main,
            id: 'dashboard',
            href: `${ORIGIN}/`,
            analyticsLabel: 'ph-subnav-cloud-dashboard',
        },
        {
            label: messages.cloudPlans,
            id: 'plans',
            href: `${ORIGIN}/#plans_calculator/`,
            analyticsLabel: 'ph-subnav-cloud-plans',
        },
        {
            label: messages.download,
            id: 'download',
            href: `${ORIGIN}/dashboard/download/`,
            analyticsLabel: 'ph-subnav-cloud-download',
        },
        {
            label: messages.faq,
            id: 'faq',
            href: `${ORIGIN}/dashboard/faq/`,
            analyticsLabel: 'ph-subnav-cloud-faq',
        },
    ],
} as const satisfies NavigationItem;

export default cloud;
