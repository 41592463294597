import React from 'react';

import { useBreakpoints } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import LangDropdown from '@components/LangDropdown';

import styles from './Footer.module.css';
import FooterGrid from './FooterGrid';
import FooterSocial from './FooterSocial';
import { anotherMessages } from './messages';

function FooterTop() {
    const { tabletSmMax: isMobile } = useBreakpoints(['tabletSmMax']);
    const { formatMessage } = useIntl();

    return (
        <div className={styles.top}>
            {isMobile && (
                <FooterSocial />
            )}

            <FooterGrid />

            <div className={styles.aside}>
                <LangDropdown
                    isMobile={isMobile}
                    tooltipId="footer-lang"
                    title={(
                        <p className={cn(styles.baseText, styles.langTitle)}>
                            {`${formatMessage(anotherMessages.language)}:`}
                        </p>
                    )}
                />
                {!isMobile
                    && <FooterSocial />}
            </div>
        </div>
    );
}

export default FooterTop;
