/* eslint-disable no-underscore-dangle */
import {
    useState, useEffect, useCallback, type FC,
} from 'react';

import { useAnalytics } from '@vkplay/analytics';
import {
    getShortLocale, getUserCMPConsent,
} from '@vkplay/shared';

import { checkNeedToLoadCookiebot } from './constants';
import { consentManagerScript } from './cookieConsent';
import './CookiesTerms.module.css';

import type { CookiesTermsProps, ConsentParamsArrType } from '@components/CookiesTerms/types';

function getCrawlerUrl(id: number) {
    // eslint-disable-next-line max-len,no-return-assign
    return (new Image()).src = `https://delivery.consentmanager.net/delivery/addurl.php?id=${id}&h=${encodeURIComponent(window.location.href)}`;
}

const CookiesTerms: FC<CookiesTermsProps> = ({
    isCis, userLoaded, isDisabled, locale,
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const needLoadCookiebot = checkNeedToLoadCookiebot() && !isDisabled;
    const { setIsAnalyticsAllowed } = useAnalytics();
    const isNotCisRegion = userLoaded && !isCis;

    const lang = getShortLocale(locale);

    const onAcceptCookiebot = useCallback(() => {
        const consentExist = getUserCMPConsent();

        if (consentExist) {
            setIsAnalyticsAllowed(true);
        }
    }, [userLoaded]);

    const onScriptLoad = () => {
        setIsLoaded(false);

        // eslint-disable-next-line no-underscore-dangle
        if (window.__cmp) {
            // eslint-disable-next-line no-underscore-dangle
            window.__cmp(
                'addEventListener',
                ['consent', onAcceptCookiebot, false],
                null,
            );
        }
    };

    useEffect(() => {
        if (!userLoaded) {
            return;
        }

        if (needLoadCookiebot) { // pixel for consent manager crawler
            getCrawlerUrl(60055);
        }

        if (isNotCisRegion && needLoadCookiebot && !isLoaded) {
            window.cmp_setlang = lang;
            consentManagerScript(onScriptLoad);
        }
    }, [isNotCisRegion, isLoaded, userLoaded]);

    // Убрать слушатель события принятия консента
    useEffect(() => (() => {
        // eslint-disable-next-line no-underscore-dangle
        if (window.__cmp) {
            // eslint-disable-next-line no-underscore-dangle
            window.__cmp(
                'removeEventListener',
                ['consent', onAcceptCookiebot, false],
                null,
            );
        }
    }), []);

    return null;
};

export default CookiesTerms;

declare global {
    interface Window {
        cmp_setlang: string;
        __cmp: (event: string, params?: ConsentParamsArrType[], optional?: null) => void;
    }
}
