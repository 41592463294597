export const MOB_CONTAINER_ID = '#mob-nav-container';

export const dateOpts: {[key: string]: string} = {
    day: 'numeric',
    month: 'short',
    hour: 'numeric',
    minute: 'numeric',
};

export const notificationDate = (created: number, locale: string) => new Date(created * 1000)
    .toLocaleDateString(locale, dateOpts)
    .split('.')
    .join('');

export const notifHighTextRegexp = /((?:[[<]{2}|\[url=[^\]\s]*\])[\s\S]*?(?:[\]>]{2}|\[\/url\]))/g;

export const highlightRegexp = /\[\[([\s\S]+)\]\]/g;
export const promoRegexp = /<<([\s\S]+)>>/;
export const urlRegexp = /\[url=[^\]\s]*\]([\s\S]+)\[\/url\]/g;
export const friendRegexp = /«([\s\S]+)»/;
