import {
    CLOUD_HOST, DEVDOCS_HOST, DEVELOPERS_HOST, GAMES_HOST, MARKET_HOST,
    MEDIA_HOST, PROFILE_HOST, PVP_HOST, SUPPORT_HOST,
} from '@services/hosts';

import {
    catalogMessages, cloudMessages, marketMessages, mediaMessages, otherMessages, streamMessages, tourneyMessages,
} from './messages';

const catalogLinks = [
    {
        title: catalogMessages.catalog,
        href: `https://${GAMES_HOST}/play/`,
    },
    {
        title: catalogMessages.catalogAvailable,
        href: `https://${GAMES_HOST}/play/`,
    },
    {
        title: catalogMessages.catalogFree,
        href: `https://${GAMES_HOST}/play/is_free/`,
    },
    {
        title: catalogMessages.catalogDiscounts,
        href: `https://${GAMES_HOST}/play/sale/`,
    },
    {
        title: catalogMessages.catalogMyGames,
        href: `https://${PROFILE_HOST}/profile/games`,
    },
];

const cloudLinks = [
    {
        title: cloudMessages.cloud,
        href: `https://${CLOUD_HOST}`,
    },
    {
        title: cloudMessages.cloudMain,
        href: `https://${CLOUD_HOST}/`,
    },
    {
        title: cloudMessages.cloudRates,
        href: `https://${CLOUD_HOST}/#plans_calculator`,
    },
    {
        title: cloudMessages.cloudDownload,
        href: `https://${CLOUD_HOST}/dashboard/download/`,
    },
    {
        title: cloudMessages.cloudFAQ,
        href: `https://${CLOUD_HOST}/dashboard/faq/`,
    },
];

const marketLinks = [
    {
        title: marketMessages.market,
        href: `https://${MARKET_HOST}/`,
    },
    {
        title: marketMessages.goods,
        href: `https://${MARKET_HOST}/`,
    },
    {
        title: marketMessages.refill,
        href: `https://${MARKET_HOST}/shop/255/`,
    },
];

const liveLinks = [
    {
        title: streamMessages.streams,
        href: `https://${GAMES_HOST}/live/`,
    },
    {
        title: streamMessages.often,
        href: `https://${GAMES_HOST}/live/`,
    },
    {
        title: streamMessages.online,
        href: `https://${GAMES_HOST}/live/`,
    },
];

const mediaLinks = [
    {
        title: mediaMessages.media,
        href: `https://${MEDIA_HOST}/`,
    },
    {
        title: mediaMessages.allMaterials,
        href: `https://${MEDIA_HOST}/`,
    },
    {
        title: mediaMessages.gameNews,
        href: `https://${MEDIA_HOST}/news/`,
    },
    {
        title: mediaMessages.articles,
        href: `https://${MEDIA_HOST}/feat/`,
    },
    {
        title: mediaMessages.guides,
        href: `https://${MEDIA_HOST}/secret/`,
    },
];

const pvpLinks = [
    {
        title: tourneyMessages.tourney,
        href: `https://${PVP_HOST}/`,
    },
    {
        title: tourneyMessages.review,
        href: `https://${PVP_HOST}/`,
    },
    {
        title: tourneyMessages.allTourneys,
        href: `https://${PVP_HOST}/tournaments`,
    },
];

const otherLinks = [
    {
        title: otherMessages.other,
        href: '',
    },
    {
        title: otherMessages.developers,
        href: `https://${DEVELOPERS_HOST}/welcome`,
    },
    {
        title: otherMessages.contacts,
        href: `https://${DEVDOCS_HOST}/contacts`,
    },
    {
        title: otherMessages.support,
        href: `https://${SUPPORT_HOST}/`,
    },
];

export const bottomLinks = {
    terms: `https://${DEVDOCS_HOST}/terms_vkp/tou_vkp`,
    policy: {
        ru_RU: `https://${DEVDOCS_HOST}/terms_vkp/privacy_vkp`,
        en_US: `https://${DEVDOCS_HOST}/terms_vkp/privacy_world_vkp`,
    },
    use_recs: `https://${DEVDOCS_HOST}/terms_vkp/adv_algo_rules`,
    imprint: `https://${DEVDOCS_HOST}/terms_vkp/imprint_vkp`,
};

export const COLUMNS = [
    { id: 'catalog', data: catalogLinks },
    { id: 'cloud', data: cloudLinks },
    { id: 'market', data: marketLinks },
    { id: 'live', data: liveLinks },
    { id: 'media', data: mediaLinks },
    { id: 'pvp', data: pvpLinks },
    { id: 'other', data: otherLinks },
] as const;

export const SOCIAL = [
    {
        href: 'https://vk.com/play',
        icon: 'social-vk',
    },
    {
        href: 'https://t.me/vkplay_ru',
        icon: 'social-telegram',
    },
    {
        href: 'https://dzen.ru/vkpm',
        icon: 'social-dzen',
    },
];
