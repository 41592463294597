import React, {
    type SyntheticEvent,
    useContext, useRef, useState,
} from 'react';

import { Backend, inGamecenter } from '@vkplay/shared';
import { Button } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import messages from '@components/TermsDialog/messages';
import styles from '@components/TermsDialog/Terms.module.css';
import TermsDetails from '@components/TermsDialog/TermsDetails';
import SessionContext from '@context/SessionContext';
import Checkbox from '@kit/Checkbox';
import Tooltip from '@kit/Tooltip';
import { getLogoutUrl, Urls } from '@services/hosts';

import type { TermsContentProps } from '@components/TermsDialog/types';

const CLIENT_ID = 'vkplay.com';

function TermsContent({
    setOpen,
    cisTerms,
    worldTerms,
}: TermsContentProps) {
    const [mainRulesAccepted, setMainRulesAccepted] = useState(false);
    const [adRulesAccepted, setAdRulesAccepted] = useState(false);

    const { session: { locale, token } } = useContext(SessionContext);

    const { formatMessage } = useIntl();
    const langRef = useRef<'ru' | 'en'>(locale === 'ru_RU' ? 'ru' : 'en');

    const handleConfirmClick = async () => {
        try {
            if (!window.CSRF_JWT_TOKEN) {
                window.CSRF_JWT_TOKEN = token;
            }

            const response = await Backend.post(Urls.terms_accept, {
                client_id: CLIENT_ID,
                register: 1,
            });

            if (response.status === 200 || response.status === 'ok') {
                if (setOpen) {
                    setOpen(false);

                    /*
                    TODO: УБРАТЬ ПОСЛЕ ВЫПИЛА СТАРОЙ ШАПКИ
                     */
                    window.location.reload();
                }

                if (inGamecenter && window.gc_close_window) {
                    window.gc_close_window();
                }
            } else {
                throw response.status;
            }
        } catch (e) {
            console.error(e);
            window.location.reload();
        }
    };

    const handleLogout = (event: SyntheticEvent) => {
        if (window.gc_logout) {
            event.preventDefault();
            event.stopPropagation();

            window.gc_logout();

            if (window.gc_close_window) window.gc_close_window();
        }
    };

    return (
        <div className={cn(styles.wrapper, { [styles.app]: !setOpen })}>
            <h4 className={styles.header}>
                {formatMessage(messages.title)}
            </h4>
            <p className={cn(styles.subHeader, styles.text)}>
                {formatMessage(messages.descr)}
            </p>

            <div className={styles.detailsGroup}>
                <TermsDetails
                    terms={cisTerms}
                    lang={langRef.current}
                />

                <TermsDetails
                    terms={worldTerms}
                    cis={false}
                    lang={langRef.current}
                />
            </div>

            <p className={cn(styles.accept, styles.text)}>
                {formatMessage(messages.vkId)}
            </p>

            <div className={styles.checkboxes}>
                <Checkbox
                    checked={mainRulesAccepted}
                    onChange={(val) => {
                        setMainRulesAccepted(val);
                    }}
                    label={formatMessage(messages.accept)}
                />

                <Checkbox
                    checked={adRulesAccepted}
                    onChange={(val) => {
                        setAdRulesAccepted(val);
                    }}
                    label={formatMessage(messages.newsletter)}
                />
            </div>

            <div className={styles.actions}>
                <Button
                    isDisabled={!mainRulesAccepted}
                    onClick={handleConfirmClick}
                    className={styles.bottomBtn}
                    appearance="overlay"
                    size="lg"
                    data-tooltip-id="accept-terms"
                >
                    {formatMessage(messages.continue)}
                </Button>

                {!mainRulesAccepted && (
                    <Tooltip
                        id="accept-terms"
                        content={formatMessage(messages.acceptHint)}
                    />
                )}

                <Button
                    component="a"
                    href={getLogoutUrl()}
                    className={styles.bottomBtn}
                    appearance="neutral"
                    size="lg"
                    onClick={handleLogout}
                >
                    {formatMessage(messages.logoutShort)}
                </Button>
            </div>
        </div>
    );
}

export default TermsContent;
