import React, {
    createContext, useEffect, useState, useMemo,
} from 'react';

import { AnalyticsProvider } from '@vkplay/analytics';
import {
    Backend, Domains, WSD, CIS_REGIONS,
} from '@vkplay/shared';

import CookiesTerms from '@components/CookiesTerms';
import { checkNeedToLoadCookiebot } from '@components/CookiesTerms/constants';
import PageView from '@components/PageView';
import defaultLang from '@services/defaultLang';
import { getAnalyticsPreset, Urls, isPartner } from '@services/hosts';

import type { SessionContextType, SessionProps, SessionType } from '@context/types';

const DEFAULT_SESSION = {
    locale: defaultLang,
    profile: undefined,
    session: undefined,
};

const SessionContext = createContext<SessionContextType>({
    session: DEFAULT_SESSION,
    noSession: false,
    loaded: false,
});

export function SessionProvider({
    user, onGetUser, children, config,
}: SessionProps) {
    const [loaded, setLoaded] = useState(!!user);
    const [noSession, setNoSession] = useState((user && !user.profile) || false);
    const [session, setSession] = useState<SessionType>(user || DEFAULT_SESSION);
    const [analyticsReady, setAnalyticsReady] = useState(false);
    const isCis = loaded && CIS_REGIONS.includes(session.country_id || '');
    const isNotCis = loaded && !isCis;
    const hasCookieBot = checkNeedToLoadCookiebot() && isNotCis && !config.noCookieBot;

    const analyticsPreset = getAnalyticsPreset();

    const onLoadAnalytics = () => {
        setAnalyticsReady(true);
    };

    const sessionValue = useMemo(() => ({
        session,
        noSession,
        loaded,
    }), [session, noSession, loaded]);

    useEffect(() => {
        if (user) {
            return;
        }

        const getUser = async () => {
            try {
                const currentSession = await Backend.get(Urls.get_user);

                setSession(currentSession);

                if (!currentSession.profile) {
                    setNoSession(true);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoaded(true);
            }
        };

        getUser();
    }, [user]);

    useEffect(() => {
        if (onGetUser && loaded) {
            onGetUser(session);
        }

        if (loaded) {
            window.country_id = session.country_id;
        }
    }, [loaded, session]);

    useEffect(() => {
        if (!session.profile?.user_id || (config.simplified && !config.enableWSD)) return;

        /*
           Prod:
           Domains.wsd

           Установить при разработке, будет потягивать со stage
           ['ws0.api.dev-vkplay.ru']
         */
        WSD.init(session.profile?.user_id, Domains.wsd);

        if (config.onInitWSD) {
            config.onInitWSD(WSD);
        }
    }, [session.profile?.user_id]);

    return (
        <SessionContext.Provider
            value={sessionValue}
        >
            <AnalyticsProvider
                project={isPartner ? 'partner' : 'eva'}
                onLoad={onLoadAnalytics}
                isAnalyticsAllowed={!hasCookieBot}
                {...(analyticsPreset && {
                    extraProject: analyticsPreset,
                })}
            >
                <CookiesTerms
                    isCis={isCis}
                    userLoaded={loaded}
                    isDisabled={!!config.noCookieBot}
                    locale={session.locale}
                />
                <PageView
                    isReady={analyticsReady}
                    userId={session.session?.user_id}
                />
                {children}
            </AnalyticsProvider>
        </SessionContext.Provider>
    );
}

export default SessionContext;
