import React, { useState } from 'react';

import { inGamecenter } from '@vkplay/shared';
import {
    Button, Icon, useBreakpoints, WrapperButton,
} from '@vkplay/ui';
import cn from 'classnames';
import { InView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';

import NotificationLabel from '@components/TopNav/Notifications/Notification/NotificationLabel';
import NotificationPicture from '@components/TopNav/Notifications/Notification/NotificationPicture';

import styles from './Notification.module.css';
import { notificationDate } from '../constants';
import { handleFriendRequest } from '../helpers';
import messages from '../messages';

import type { CustomNotificationProps } from '../types';
import type { SyntheticEvent } from 'react';

function FriendRequestNotification(props: CustomNotificationProps) {
    const {
        notification: {
            id,
            data: {
                link_url,
                project_name,
            },
            created,
            is_read,
            raw_data: {
                user_slug,
            },
        },
        onRead,
        onClose,
        onView,
    } = props;

    const { tabletMdMax: isMobile } = useBreakpoints(['tabletMdMax']);
    const { formatMessage, locale } = useIntl();
    const [label, setLabel] = useState('');

    const handleAccept = async () => {
        await handleFriendRequest(user_slug, 'accept', id);
        setLabel(formatMessage(messages.friendRequestAccepted));
    };

    const handleDecline = async () => {
        await handleFriendRequest(user_slug, 'decline', id);
        setLabel(formatMessage(messages.friendRequestDeclined));
    };

    const handleClick = (event: SyntheticEvent) => {
        if (inGamecenter && window.gc_open_url_mainwnd) {
            event.stopPropagation();
            event.preventDefault();

            window.gc_open_url_mainwnd(link_url);

            if (window.gc_close_window) {
                window.gc_close_window();
            }
        }
    };

    return (
        <InView
            className={cn(styles.wrapper, {
                [styles.updated]: !!label,
                [styles.new]: !is_read && !onClose,
                [styles.inBubble]: !!onClose,
            })}
            onMouseEnter={onRead}
            onChange={onView}
            triggerOnce
            threshold={1}
            rootMargin="0px 0px 60px 0px"
            skip={is_read || !isMobile || inGamecenter}
        >
            <div className={styles.content}>
                <NotificationPicture {...props} />

                <div className={styles.data}>
                    <span className={styles.title}>
                        <a
                            href={link_url}
                            onClick={handleClick}
                        >
                            {project_name}
                        </a>
                        <span>
                            {` ${formatMessage(messages.friendRequest)}`}
                        </span>
                    </span>
                    <span className={styles.date}>
                        {notificationDate(created, locale)}
                    </span>

                    {(!label) && (
                        <div className={styles.actions}>
                            <Button
                                size="sm"
                                appearance="overlay"
                                onClick={handleAccept}
                            >
                                {formatMessage(messages.accept)}
                            </Button>
                            <Button
                                size="sm"
                                appearance="neutral"
                                onClick={handleDecline}
                            >
                                {formatMessage(messages.decline)}
                            </Button>
                        </div>
                    )}
                </div>

                {onClose && (
                    <WrapperButton
                        onClick={() => onClose(id)}
                        className={styles.contextMenuItem}
                    >
                        <Icon name="close" />
                    </WrapperButton>
                )}
            </div>

            <NotificationLabel
                label={label}
            />

            {(!is_read && !isMobile && !onClose) && (
                <div className={styles.newLabel} />
            )}
        </InView>
    );
}

export default FriendRequestNotification;
