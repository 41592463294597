import React from 'react';

import { Button, Icon } from '@vkplay/ui';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useSearchContext } from './context/SearchContext';
import messages from './messages';
import styles from './SearchDropdownGroup.module.css';
import LoaderSvg from './svg/loader.svg?react';

import type { SearchDropdownGroupProps } from './types';
import type { FC, MouseEvent } from 'react';

const SearchDropdownGroup: FC<SearchDropdownGroupProps> = ({
    id,
    children,
    title,
    count,
    isLoading,
    hasNextPage,
    onLoadMore,
}) => {
    const { formatMessage } = useIntl();

    const { size } = useSearchContext();

    const loadMore = (e: MouseEvent) => {
        e.stopPropagation();

        onLoadMore?.();
    };

    const isFullscreen = size === 'fullscreen';

    return (
        <div
            className={cn(styles.root, { [styles.fullscreen]: isFullscreen })}
            data-id={`search-dropdown__group_${id}`}
        >
            <div className={styles.title} data-id="search-dropdown__group-title">
                {title}
                {count && (
                    <>
                        &nbsp;
                        <span className={styles.count}>{count}</span>
                    </>
                )}
            </div>
            <div className={styles.content}>{children}</div>
            {hasNextPage && (
                <div className={styles.buttonWrapper}>
                    {isLoading
                        ? (
                            <div className={styles.loader}>
                                <LoaderSvg />
                            </div>
                        )
                        : (
                            <Button
                                className={styles.button}
                                size="md"
                                leftIcon={<Icon name="arrow-down-outline" />}
                                appearance="neutral"
                                mode={isFullscreen ? 'outline' : undefined}
                                onClick={loadMore}
                            >
                                {formatMessage(messages.controlShowMore)}
                            </Button>
                        )}
                </div>
            )}
        </div>
    );
};

export default SearchDropdownGroup;
