import React, { useState, useContext } from 'react';

import { useBreakpoints } from '@vkplay/ui';
import cn from 'classnames';

import Search from '@components/Search';
import Wishlist from '@components/Wishlist';
import AppContext from '@context/AppContext';
import useAnalytics from '@hooks/useAnalytics';
import VKPlayLogo from '@kit/VKPlayLogo';
import { HiddenElementsTypes } from '@services/constants';
import { Urls } from '@services/hosts';

import Notifications from './Notifications';
import Profile from './Profile';
import styles from './TopNav.module.css';

export default function TopNav() {
    const [hiddenButtons, setHiddenButtons] = useState(false);

    const { handleSearchEvents } = useAnalytics();

    const { tabletMdMax } = useBreakpoints(['tabletMdMax']);

    const {
        onMainClick, hiddenElements, simplified, transparent,
    } = useContext(AppContext);

    const currentUrl = `${window.location.origin}${window.location.pathname}`;

    const hideEverything = tabletMdMax && currentUrl !== Urls.main && !simplified;
    const hideNotifications = hiddenElements?.includes(HiddenElementsTypes.Notifications);
    const hideSearch = hiddenElements?.includes(HiddenElementsTypes.Search);
    const hideFavorites = hiddenElements?.includes(HiddenElementsTypes.Favorites);
    const hideUser = hiddenElements?.includes(HiddenElementsTypes.User);

    if (hideEverything) {
        return null;
    }

    const toggleButtons = (value: boolean) => {
        setHiddenButtons(value);
    };

    return (
        <div
            className={cn(styles.wrapper, {
                [styles.transparent]: transparent,
            })}
        >
            <div
                className={cn(styles.container, {
                    [styles.simplified]: simplified,
                })}
            >
                <div className={styles.logo}>
                    <a
                        href={Urls.main}
                        className={styles.logoLink}
                        onClick={onMainClick}
                        {...(simplified && {
                            target: '_blank',
                        })}
                    >
                        <VKPlayLogo className={styles.logoImg} />
                    </a>
                </div>

                {!simplified
                && (
                    <div className={styles.leftPart}>
                        {!hideSearch && (
                            <Search
                                className={styles.search}
                                onToggle={toggleButtons}
                                onEvent={handleSearchEvents}
                            />
                        )}

                        {!hiddenButtons && (
                            <div className={styles.leftPartButtonGroup}>
                                {!hideNotifications && (
                                    <Notifications />
                                )}

                                {!hideFavorites && (
                                    <Wishlist />
                                )}
                            </div>
                        )}
                    </div>
                )}

                {!hideUser && <Profile />}
            </div>
        </div>
    );
}
