import { defineMessages } from 'react-intl';

import type { IntlMessagesType } from '@/types';

const messages: IntlMessagesType = defineMessages({
    text: {
        id: 'app.stub.safari',
        defaultMessage: 'Сервис может некорректно работать в Safari. '
            + 'Для стабильной работы рекомендуем использовать другой браузер.',
    },
    textShort: {
        id: 'app.stub.safari.short',
        defaultMessage: 'Сервис может некорректно работать в Safari',
    },
});

export default messages;
