import type { PropsWithChildren, SyntheticEvent } from 'react';

export enum NotificationTemplate {
    Gift = '442',
    FriendRequest = '443',
    RequestAccepted = '444',
    RequestDeclined = '445',
}

export type NotificationControl = {
    authorization: string,
    gc_link_url: string,
    has_next_template: boolean,
    id: number,
    link_url: string,
    method: 'N' | 'G' | 'P', // new | get | post
    name: string,
    position: number,
    type: 'P' | 'S' | 'A' // primary | secondary | additional
}

export type NotificationItem = {
    created: number,
    id: string,
    controls: Array<NotificationControl>,
    data: {
        big_image_url: string,
        gc_link_url: string,
        image_url: string,
        link_text: string,
        link_url: string,
        pixel_click_url: string,
        pixel_url: string,
        project_name: string,
        text: string,
        title: string,
        unsubscribe_url: string,
    },
    raw_data: {
        image_url: string,
        language: string,
        post_type: string,
        project_name: string,
        user_name: string,
        user_slug: string,
    },
    is_read: boolean,
    template_id: NotificationTemplate & string,
    type: 'notification_mygames' | 'notification_update',
    visibility: number,
    needs_bubble: boolean,
    removeBubble?: boolean,
}

export type NotificationsProps = {
    onOpen: () => void,
}

export type NotificationsContextProps = PropsWithChildren;

export type NotificationsContextState = {
    list: Array<NotificationItem>,
    unread: number,
    loading: boolean,
    nextPage: null | number,
    needUpdate: boolean,
    onReadNotification: (id: string) => void,
    onDeleteNotification: () => void,
    onCheckAll: () => void,
    onOpenNotification: () => void,
}

export type NotificationProps = {
    notification: NotificationItem,
    onClose?: (id: string) => void,
}

export type NotificationActionsProps = NotificationProps & {
    inBubble?: boolean,
}

export type UnsubscribedNotificationProps = NotificationProps & {
    revert: (event: SyntheticEvent) => void,
}

export type CustomNotificationProps = NotificationProps & {
    onDelete: (event: SyntheticEvent) => void,
    onRead: () => void,
    onView: (value: boolean) => void,
}

export type NotificationsHeaderProps = {
    hasNew: boolean,
    loading: boolean,
}

export type ContextMenuProps = {
    id: string,
    href: string,
    onDelete: (event: SyntheticEvent) => void,
    onHide?: (event: SyntheticEvent) => void,
}

export type NotificationLabelProps = {
    label?: string,
    position?: 'bottom' | 'top',
}

export type MobileNotificationsHeaderProps = {
    onClose: (event: SyntheticEvent) => void,
}

export type MobileNotificationOverlayProps = {
    open: boolean,
    onClose: (event: SyntheticEvent) => void,
}
