import React from 'react';

import { Icon, useBreakpoints } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import messages from './messages';
import styles from './ServiceStub.module.css';

const ServiceStub = () => {
    const { formatMessage } = useIntl();
    const { tabletMdMax } = useBreakpoints();

    return (
        <div className={styles.container}>
            <div className={styles.notice}>
                <Icon name="error-circle" />
                {tabletMdMax ? formatMessage(messages.textShort) : formatMessage(messages.text)}
            </div>
        </div>
    );
};

export default ServiceStub;
