import { PVP_HOST } from '@services/hosts';

import messages from './messages';

import type { NavigationItem } from './types';

const ORIGIN = `https://${PVP_HOST}`;

const pvp = {
    id: 'pvp',
    label: messages.pvp,
    href: `${ORIGIN}/`,
    icon: 'tournament-outline',
    analyticsLabel: 'ph-menu-pvp',
    subnav: [
        {
            label: messages.overview,
            id: 'main',
            href: `${ORIGIN}/`,
            analyticsLabel: 'ph-subnav-pvp-main',
        },
        {
            label: messages.tournaments,
            id: 'tournaments',
            href: `${ORIGIN}/tournaments`,
            analyticsLabel: 'ph-subnav-pvp-tournaments',
        },
        {
            label: messages.player,
            id: 'player',
            href: `${ORIGIN}/profile`,
            allowPartialMatch: true,
            include: [
                `${ORIGIN}/player/`,
            ],
            analyticsLabel: 'ph-subnav-pvp-player',
        },
    ],
} as const satisfies NavigationItem;

export default pvp;
