import React, {
    useEffect, useContext, type FC, type MouseEvent,
} from 'react';

import { useAnalyticsMethods } from '@vkplay/analytics';
import { triggerPixel } from '@vkplay/shared';
import { Button, Icon, useBreakpoints } from '@vkplay/ui';
import { useIntl } from 'react-intl';

import AppContext from '@context/AppContext';
import Tooltip from '@kit/Tooltip';
import { isStore } from '@services/hosts';

import {
    hasRefillParam,
    STATS_VALUE,
    updateUrlParam,
    paymentSteamUrl,
    paymentSteamUrlGC,
} from './helpers';
import messages from './messages';
import styles from './SteamRefill.module.css';

const SteamRefill: FC = () => {
    const { onSidenavClick } = useContext(AppContext);
    const { pushReachGoal } = useAnalyticsMethods();
    const { tabletMdMax } = useBreakpoints(['tabletMdMax']);
    const { formatMessage } = useIntl();

    const handleBtnClick = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        if (onSidenavClick && isStore) {
            triggerPixel(`https://trk.mail.ru/c/${STATS_VALUE}`);
            onSidenavClick(e, 'payment_steam');
        }

        pushReachGoal({
            params: {
                category: 'steam_wallet',
                action: 'steam_wallet_click',
            },
        });
    };

    useEffect(() => {
        pushReachGoal({
            params: {
                category: 'steam_wallet',
                action: 'steam_wallet_show',
            },
        });

        if (hasRefillParam) {
            if (window.location.pathname.includes('/services/payment_steam')) {
                updateUrlParam();
            } else {
                window.location.replace(paymentSteamUrlGC);
            }
        }
    }, []);

    return (
        <Button
            component="a"
            className={styles.steamBtn}
            appearance="neutral"
            onClick={handleBtnClick}
            href={paymentSteamUrl}
            {...(tabletMdMax ? {
                mode: 'outline',
            } : {
                'data-tooltip-id': 'steam-btn',
                'data-tooltip-position-strategy': 'fixed',
            })}
        >
            <span className={styles.icon}>
                <Icon name="wallet-outline" />
            </span>
            <span className={styles.label}>{formatMessage(messages.btnName)}</span>

            <Tooltip
                id="steam-btn"
                content={formatMessage(messages.btnTooltip)}
                className={styles.tooltip}
            />
        </Button>
    );
};

export default SteamRefill;
